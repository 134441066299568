




import { Action } from 'vuex-class';
import { Component, Mixins } from 'vue-property-decorator';
import UserModule from '@/app/user/store';
import { UserProfileDto } from '@/app/user/dto/user.dto';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import EditProfileForm from './edit-profile-form.vue';

@Component({
  components: {
    EditProfileForm,
  },
})
export default class EditProfile extends Mixins(HandlesErrorMixin) {
  private loading = false;

  @Action
  private editProfile!: typeof UserModule.prototype.editProfile;

  async onSubmit(data: UserProfileDto) {
    this.loading = true;

    try {
      await this.editProfile(data);

      this.$notify.success(this.$t('success.profileEdited').toString());
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }
}
