
















































import { Mixins, Component } from 'vue-property-decorator';
import FormMixin from '@/app/core/mixins/form.mixin';
import ChangePasswordDto from '@/app/user/dto/change-password.dto';
import { atLeast, minLength, required } from '@/app/core/validation';

@Component
export default class ChangePasswordForm extends Mixins(FormMixin) {
  private showPassword = false;
  private showNewPassword = false;

  protected data: ChangePasswordDto = {
    password: '',
    newPassword: '',
  };

  protected rules = {
    password: [required],
    newPassword: [
      required,
      minLength(8),
      atLeast(1).digits,
      atLeast(1).lowercaseLetters,
      atLeast(1).uppercaseLetters,
    ],
  };

  afterSubmit() {
    this.reset();
  }
}
