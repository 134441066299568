




import { Component, Mixins } from 'vue-property-decorator';
import ChangePasswordDto from '@/app/user/dto/change-password.dto';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import userProfileService from '@/app/user/services/user-profile.service';
import ChangePasswordForm from './change-password-form.vue';

@Component({
  components: {
    ChangePasswordForm,
  },
})
export default class ChangePassword extends Mixins(HandlesErrorMixin) {
  private loading = false;

  async onSubmit(data: ChangePasswordDto) {
    this.loading = true;

    try {
      await userProfileService.changePassword(data);

      this.$notify.success(this.$t('success.passwordChanged').toString());
    } catch (error) {
      if (this.isBadRequestError(error)) {
        this.$notify.error({
          title: this.$t('error.incorrectPassword.title').toString(),
          message: this.$t('error.incorrectPassword.message').toString(),
        });
      } else {
        this.handleError(error);
      }
    } finally {
      this.loading = false;
    }
  }
}
