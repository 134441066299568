



























import { Action, State } from 'vuex-class';
import { Component, Mixins, Ref } from 'vue-property-decorator';
import UserModule from '@/app/user/store';
import UserDto from '@/app/user/dto/user.dto';
import Avatar from '@/app/core/components/avatar.vue';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';

@Component({
  components: {
    Avatar,
  },
})
export default class EditProfile extends Mixins(HandlesErrorMixin) {
  @State((state) => state.user)
  private user!: UserDto;

  @Ref('fileInput') fileInput!: HTMLInputElement;

  private loading = false;

  get picture() {
    return this.user?.profile?.picture;
  }

  @Action
  private editProfilePicture!: typeof UserModule.prototype.editProfilePicture;

  openFileBrowser() {
    this.fileInput.click();
  }

  onFileInputChange(event: Event) {
    const target = event.target as HTMLInputElement;

    if (!target.files || target.files.length === 0) return;

    const picture = target.files[0];
    this.uploadPicture(picture);
  }

  async uploadPicture(picture: File) {
    this.loading = true;

    try {
      await this.editProfilePicture(picture);
    } catch (error) {
      this.handleError(error);
    } finally {
      this.loading = false;
    }
  }
}
