





































































import { State } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import UserDto from '@/app/user/dto/user.dto';
import EditProfile from '../components/edit-profile.vue';
import ChangePassword from '../components/change-password.vue';
import ProfilePicture from '../components/profile-picture.vue';

@Component({
  components: {
    EditProfile,
    ChangePassword,
    ProfilePicture,
  },
  metaInfo(this: MyProfile) {
    return { title: this.$t('pageTitle.myProfile').toString() };
  },
})
export default class MyProfile extends Vue {
  @State(state => state.user)
  private user!: UserDto;

  @State(state => state.auth.role)
  private role!: string;

  private tab = 0;

  get email() {
    return this.user?.email || 'Loading user email..'
  }

  get fullname() {
    if (!this.user?.profile) return 'Loading user name...';

    return `${this.user.profile.firstname} ${this.user.profile.lastname}`;
  }

  get picture() {
    return this.user?.profile?.picture;
  }
}
